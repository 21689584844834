<template>
  <van-list v-model="loadingVal" :finished="finishedVal" finished-text="没有更多了" @load="onDataLoad">
    <course-info-item class="course-info-item" v-for="(item, index) in list" :key="index" :courseGuid="item.CourseGuid" :courseName="item.CourseName" :courseAvailableQuantity="item.CourseAvailableQuantity" :courseSoldQuantity="item.CourseSoldQuantity" :courseOriginalPrice="item.CourseOriginalPrice" :courseSellingPrice="item.CourseSellingPrice" :courseTeacherList="item.CourseTeacherList" @click="onItemClick(item)"></course-info-item>
  </van-list>
</template>

<script>
import CourseInfoItem from "@/components/MT/CourseInfoItem";
export default {
  name: "CourseInfoList",
  components: {
    CourseInfoItem
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    finished: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {};
  },
  computed: {
    loadingVal: {
      get: function () {
        return this.loading;
      },
      set: function (val) {
        this.$emit('update:loading', val);
      }
    },
    finishedVal: {
      get: function () {
        return this.finished;
      },
      set: function (val) {
        this.$emit('update:finished', val);
      }
    },
  },
  methods: {
    onDataLoad() {
      this.$emit("data-load");
    },
    onItemClick(item) {
      this.$emit("item-click", item);
    },
  },
};
</script>

<style lang="less" scoped>
.course-info-item {
  margin: 20px 0;
}
</style>
