<template>
  <div class="item-box" @click.stop="onClick">
    <div class="row-box1">
      <el-image class="img" :src="courseImage" />
    </div>
    <div class="row-box2">
      <div class="name">{{ courseName }}</div>
    </div>
    <div class="row-box3">
      <div v-for="(teacher, index) in courseTeacherList" :key="index" class="teacher-box">
        <div class="teacher-txt">{{ teacher }}</div>
      </div>
      <div class="price-box">
        <span class="selling-price">￥{{ courseSellingPrice.toFixed(2) }}</span>
        <span class="original-price">原价￥{{ courseOriginalPrice.toFixed(2) }}</span>
      </div>
    </div>
    <div class="line"></div>
    <div class="row-box4">
      <div class="sell">限售{{ courseAvailableQuantity }} | 已售{{ courseSoldQuantity }}</div>
      <el-button class="button" type="primary" size="mini" @click.stop="onClick">免费试看</el-button>
    </div>
  </div>
</template>

<script>
import Config from "@/api/service";

export default {
  name: "CourseInfoItem",
  components: {

  },
  props: {
    courseGuid: {
      type: String,
      default: null,
    },
    courseName: {
      type: String,
      default: null,
    },
    courseAvailableQuantity: {
      type: Number,
      default: 0,
    },
    courseSoldQuantity: {
      type: Number,
      default: 0,
    },
    courseOriginalPrice: {
      type: Number,
      default: 0,
    },
    courseSellingPrice: {
      type: Number,
      default: 0,
    },
    courseTeacherList: {
      type: Array,
      default: [],
    },
  },
  computed: {
    courseImage() {
      if (this.courseGuid) {
        return `${Config.course.coverImage}?CourseGuid=${this.courseGuid}`;
      }
    },
  },
  data() {
    return {};
  },
  methods: {
    onClick(item) {
      this.$emit("click");
    },
  },
};
</script>

<style scoped lang="less">
.item-box {
  display: flex;
  flex-direction: column;
  border: 1px solid #e6e6e6;
  border-radius: 5px 5px 0 0;
  cursor: pointer;

  .row-box1 {
    .img {
      width: 100%;
      height: 120px;
      border-radius: 5px 5px 0 0;
    }
  }

  .row-box2 {
    .name {
      height: 40px;
      margin: 5px;
      font-size: 16px;
      overflow: hidden; //隐藏滚动条
      text-overflow: ellipsis; //显示省略号
      display: -webkit-box; //将对象作为弹性伸缩盒子模型显示。
      -webkit-box-orient: vertical; //从上到下垂直排列子元素（设置伸缩盒子的子元素排列方式）
      -webkit-line-clamp: 2; //这个属性不是css的规范属性，需要组合上面两个属性，表示显示的行数。
    }
  }

  .line {
    border-bottom: 1px solid #e6e6e6;
    margin: 5px;
  }

  .row-box3 {
    display: flex;
    align-items: center;
    margin: 5px;

    .teacher-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 5px;

      .teacher-txt {
        color: rgb(72, 71, 71);
        font-size: 14px;
        margin-top: 5px;
      }
    }

    .price-box {
      display: flex;
      flex-direction: column;
      margin-left: auto;

      .selling-price {
        color: #FF6602;
        font-weight: bold;
        font-size: 16px;
        display: flex;
        justify-content: flex-end;
      }

      .original-price {
        color: gray;
        font-size: 12px;
        text-decoration: line-through;
      }

    }
  }

  .row-box4 {
    display: flex;
    margin: 5px;

    .sell {
      color: gray;
      font-size: 12px;
      margin: 5px;
    }

    .button {
      margin-left: auto;
    }
  }

}
</style>
