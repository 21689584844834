<template>
  <div>
    <div class="box flex" v-if="directoryList.length > 0">
      <div class="text1">科目名称</div>
      <img src="@/assets/images/pc/right.png" class="img">
      <radio-item-group class="flex fill" v-model="value1">
        <radio-item label="全部" @click="onClear1"></radio-item>
        <radio-item v-for="item in directoryList" :label="item.DirectoryName" :name="item.DirectoryGuid" @click="onClick1"></radio-item>
      </radio-item-group>
    </div>
    <div class="box flex">
      <div class="text1">课型班次</div>
      <img src="@/assets/images/pc/right.png" class="img">
      <radio-item-group class="flex fill" v-model="value2">
        <radio-item label="全部" @click="onClear2"></radio-item>
        <radio-item label="全程班" name="全程班" @click="onClick2"></radio-item>
        <radio-item label="导学课" name="导学课" @click="onClick2"></radio-item>
        <radio-item label="基础班" name="基础班" @click="onClick2"></radio-item>
        <radio-item label="强化班" name="强化班" @click="onClick2"></radio-item>
        <radio-item label="冲刺班" name="冲刺班" @click="onClick2"></radio-item>
      </radio-item-group>
    </div>
  </div>
</template>

<script>
import RadioItem from "@/components/PC/RadioItem";
import RadioItemGroup from "@/components/PC/RadioItemGroup";
export default {
  name: "CourseInfoFilter",
  components: {
    RadioItem,
    RadioItemGroup,
  },
  props: {
    directoryList: {
      type: Array,
      default: [],
    },
    filter1: {
      type: String,
      default: null,
    },
    filter2: {
      type: String,
      default: null,
    },
  },
  computed: {
    value1: {
      get: function () {
        return this.filter1;
      },
      set: function (val) {
        this.$emit('update:filter1', val);
      }
    },
    value2: {
      get: function () {
        return this.filter2;
      },
      set: function (val) {
        this.$emit('update:filter2', val);
      }
    },
  },
  data() {
    return {
    };
  },
  methods: {
    onClear1() {
      this.value1 = '';
      this.onClick1();
    },
    onClear2() {
      this.value2 = '';
      this.onClick2();
    },
    onClick1() {
      this.$emit('filter');
    },
    onClick2() {
      this.$emit('filter');
    },
  },
  mounted() {

  }
};
</script>

<style lang="less" scoped>
.box {
  font-size: 14px;
}

.img {
  width: 15px;
  height: 15px;
  padding: 10px 0;
  margin-top: 2px;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.center {
  align-items: center;
}

.fill {
  flex: 1;
}

.text1 {
  padding: 10px;
  color: black;
  word-spacing: 24px;
}

.margin-column {
  margin: 20px 0;
}
</style>
