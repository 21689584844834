<template>
    <div :class="selected ? 'radio-box check-box_checked' : 'radio-box'" @click="onClick">
        <div class="check-text">{{ label }}</div>
    </div>
</template>

<script>

export default {
    name: "RadioBorder",
    components: {
    },
    inject: {
        RadioItemGroup: {
            default: null
        }
    },
    computed: {
        value: {
            get() {
                //获取时触发
                if (this.RadioItemGroup) {
                    return this.RadioItemGroup.value;
                }
            },
            set(val) {
                //赋值时触发
                if (this.RadioItemGroup) {
                    this.RadioItemGroup.updateValue(val);
                }
            }
        },
        selected: {
            get() {
                if (this.RadioItemGroup) {
                    return this.value == this.name;
                } else {
                    return this.checked;
                }
            },
        }
    },
    props: {
        icon: {
            type: Boolean,
            default: false,
        },
        checked: {
            type: Boolean,
            default: false,
        },
        name: {
            type: [String, Object],
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
        };
    },
    methods: {
        onClick() {
            if (this.RadioItemGroup) {
                this.RadioItemGroup.updateValue(this.name);
            } else {
                this.$emit("update:checked", this.checked);
            }
            this.$emit('click');
        }
    },
    mounted() {

    }
};
</script>

<style scoped>
.radio-box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px #FE5E03;
    border-radius: 20px;
    background-color: white;
    color: #606266;
    font-size: 12px;
    transition: all .3s;
}

.check-box_checked {
    background-color: #FE5E03;
    color: white;
    transition: all .3s;
}

.check-text {
    padding: 5px 10px;
}
</style>