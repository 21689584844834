<template>
  <div class="filter-box">
    <van-cell-group :border="false" title="选择年份">
      <radio-border-group v-model="value1" class="radio-border-group">
        <radio-border v-for="directory1 in directoryList1" class="radio-border" :label="directory1.DirectoryName" :name="directory1.DirectoryGuid" icon @click="onChange1"></radio-border>
      </radio-border-group>
    </van-cell-group>
    <van-cell-group title="选择分类">
      <radio-cell-group v-if="value1" clear v-model="value2" title="学科类别" placeholder="请选择学科类别" @change="onChange2">
        <radio-cell v-for="(directory2, index) in directoryList2" :key="index" :name="directory2.DirectoryGuid" :label="directory2.DirectoryName"></radio-cell>
      </radio-cell-group>
      <radio-cell-group v-if="value2" clear v-model="value3" title="科目名称" placeholder="请选择科目名称" @change="onChange3">
        <radio-cell v-for="(directory3, index) in directoryList3" :key="index" :name="directory3.DirectoryGuid" :label="directory3.DirectoryName"></radio-cell>
      </radio-cell-group>
      <radio-cell-group clear v-model="value4" title="课型班次" placeholder="请选择课型班次" @change="onChange4">
        <radio-cell v-for="(item, index) in courseTags" :key="index" :name="item" :label="item"></radio-cell>
      </radio-cell-group>
    </van-cell-group>
  </div>
</template>

<script>
import RadioCell from "@/components/MT/RadioCell";
import RadioCellGroup from "@/components/MT/RadioCellGroup";
import RadioBorder from "@/components/MT/RadioBorder";
import RadioBorderGroup from "@/components/MT/RadioBorderGroup";
export default {
  name: "CourseInfoFilter",
  components: {
    RadioCell,
    RadioCellGroup,
    RadioBorder,
    RadioBorderGroup,
  },
  props: {
    directoryList: {
      type: Array,
      default: [],
    },
    directoryGuid1: {
      type: String,
      default: '',
    },
    directoryGuid2: {
      type: String,
      default: '',
    },
    directoryGuid3: {
      type: String,
      default: '',
    },
    courseTag: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      courseTags: ['全程班', '导学课', '基础班', '强化班', '冲刺班'],
    };
  },
  computed: {
    value1: {
      get: function () {
        return this.directoryGuid1;
      },
      set: function (val) {
        this.$emit('update:directoryGuid1', val);
      }
    },
    value2: {
      get: function () {
        return this.directoryGuid2;
      },
      set: function (val) {
        this.$emit('update:directoryGuid2', val);
      }
    },
    value3: {
      get: function () {
        return this.directoryGuid3;
      },
      set: function (val) {
        this.$emit('update:directoryGuid3', val);
      }
    },
    value4: {
      get: function () {
        return this.courseTag;
      },
      set: function (val) {
        this.$emit('update:courseTag', val);
      }
    },
    directoryTitle2() {
      var item = this.directoryList2.find(p => p.DirectoryGuid == this.directoryGuid2);
      if (item) {
        return item.DirectoryName;
      } else {
        return this.value2;
      }
    },
    directoryTitle3() {
      var item = this.directoryList3.find(p => p.DirectoryGuid == this.directoryGuid3);
      if (item) {
        return item.DirectoryName;
      } else {
        return this.value3;
      }
    },
    courseTagTitle() {
      return this.value4;
    },
    directoryList1() {
      return this.directoryList;
    },
    directoryList2() {
      var item = this.directoryList1.find(p => p.DirectoryGuid == this.directoryGuid1);
      if (item) {
        return item.ChildDirectoryList;
      }
      return []
    },
    directoryList3() {
      var item = this.directoryList2.find(p => p.DirectoryGuid == this.directoryGuid2);
      if (item) {
        return item.ChildDirectoryList;
      }
      return []
    },
  },
  methods: {
    onChange1() {
      this.value2 = '';
      this.value3 = '';
      this.value4 = '';
      this.$emit('filter');
    },
    onChange2() {
      this.value3 = '';
      this.value4 = '';
      this.$emit('filter');
    },
    onChange3() {
      this.value4 = '';
      this.$emit('filter');
    },
    onChange4() {
      this.$emit('filter');
    },
  },
  mounted() {

  }
};
</script>

<style lang="less" scoped>
.filter-box {
  background-color: white;
  padding: 5px;
  border-radius: 10px;
}

.radio-border-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.radio-border {
  width: 48%;
}

.popup {
  height: 80%;
}

::v-deep .van-cell-group__title {
  color: black;
  font-weight: bold;
}
</style>
