<template>
    <div class="group">
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: "RadioBorderGroup",
    provide() {
        return {
            RadioItemGroup: this   //将组件本身的vue对象传递给下级
        };
    },
    model: {
        prop: 'value',
        event: 'update:value'
    },
    props: {
        value: {
            type: String,
            default: ''
        }
    },
    //设置值的时候无需触发change事件，手动点击切换再触发change事件
    // watch: {
    //     value: {
    //         immediate: false,
    //         handler(val) {
    //             this.$emit("change", val);
    //         }
    //     },
    // },
    methods: {
        updateValue(val) {
            this.$emit("update:value", val);
            this.$emit("change", val);
        }
    },
};
</script>

<style>
.group {
    width: 100%;
}
</style>