<template>
  <div class="content-box" @click.stop="onClick">
    <div class="content-row">
      <div class="content-title">{{ courseName }}</div>
    </div>
    <div class="content-row">
      <span v-for="(teacher, index) in courseTeacherList" :key="index" class="content-teacher">{{ teacher }}</span>
      <span class="content-original-price">原价￥{{ courseOriginalPrice.toFixed(2) }}</span>
      <span class="content-selling-price">￥{{ courseSellingPrice.toFixed(2) }}</span>
    </div>
    <div class="content-row">
      <div class="content-number">限售{{ courseAvailableQuantity }} | 已售{{ courseSoldQuantity }}</div>
      <van-button class="content-button" size="mini" type="danger" @click.stop="onClick">免费试看</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CourseInfoItem",
  components: {

  },
  props: {
    courseGuid: {
      type: String,
      default: null,
    },
    courseName: {
      type: String,
      default: null,
    },
    courseAvailableQuantity: {
      type: Number,
      default: 0,
    },
    courseSoldQuantity: {
      type: Number,
      default: 0,
    },
    courseOriginalPrice: {
      type: Number,
      default: 0,
    },
    courseSellingPrice: {
      type: Number,
      default: 0,
    },
    courseTeacherList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {};
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  background-color: white;
  border-radius: 10px;
  padding: 0px 10px;
}

.content-row {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.content-title {
  font-size: 16px;
  font-weight: bold;
}

.content-teacher {
  color: lightgray;
  font-size: 12px;
  margin-right: 5px;
}

.content-original-price {
  color: gray;
  font-size: 12px;
  text-decoration: line-through;
  margin-left: auto;
}

.content-selling-price {
  color: #FF6602;
  font-weight: bold;
  font-size: 16px;
}

.content-number {
  color: gray;
  font-size: 12px;
}

.content-button {
  margin-left: auto;
}
</style>
