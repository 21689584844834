<template>
  <div>
    <el-carousel class="content-carousel" height="360px">
      <el-carousel-item>
        <el-image :src="proxySwipeImage(13)"></el-image>
      </el-carousel-item>
      <el-carousel-item>
        <el-image :src="proxySwipeImage(14)"></el-image>
      </el-carousel-item>
      <el-carousel-item>
        <el-image :src="proxySwipeImage(15)"></el-image>
      </el-carousel-item>
      <el-carousel-item>
        <el-image :src="proxySwipeImage(16)"></el-image>
      </el-carousel-item>
    </el-carousel>
    <el-tabs v-model="activeTab" type="card">
      <el-tab-pane class="content-pane" v-for="directory1 in directoryList" :label="directory1.DirectoryName" :name="directory1.DirectoryGuid">
        <div v-for="directory2 in directory1.ChildDirectoryList" :key="directory2.DirectoryGuid">
          <header-entry>{{ directory2.DirectoryName }}</header-entry>
          <course-info-filter :filter1.sync="directory2.Filter1" :filter2.sync="directory2.Filter2" :directoryList="directory2.ChildDirectoryList" @filter="onFilterClick(directory2)"></course-info-filter>
          <div class="course-info-line"></div>
          <course-info-list :list="directory2.CourseInfoList" :hasMore="directory2.HasMore" @item-click="onItemClick" @more-click="onMoreClick(directory2)"></course-info-list>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import HeaderEntry from "@/components/PC/HeaderEntry";
import CourseInfoFilter from "@/components/PC/CourseInfoFilter";
import CourseInfoList from "@/components/PC/CourseInfoList";
import Tools from "@/tool";
import Config from "@/api/service";
import Manager from "@/manager";
export default {
  name: "PCModule",
  components: {
    HeaderEntry,
    CourseInfoFilter,
    CourseInfoList,
  },
  props: {
  },
  data() {
    return {
      activeTab: null,
      directoryList: [],
    };
  },
  computed: {
    proxySwipeImage() {
      return function (code) {
        return Config.proxy.image + `?ProxyCode=${this.$store.state.proxyCode}&ImageIdentity=${code}`;
      }
    }
  },
  methods: {
    //获取路由参数
    getRoutParams() {

    },
    //获取目录列表
    getCourseDirectoryList() {
      var model = {};
      this.$axios
        .post(Config.course.directoryList, model)
        .then((res) => {
          this.directoryList = res.data.Data.DirectoryList;
          //默认选中第一项
          if (this.directoryList.length > 0) {
            this.activeTab = this.directoryList[0].DirectoryGuid;
          }
          //默认课程列表
          for (let i = 0; i < this.directoryList.length; i++) {
            for (let j = 0; j < this.directoryList[i].ChildDirectoryList.length; j++) {
              //初始化过滤条件
              this.$set(this.directoryList[i].ChildDirectoryList[j], 'Filter1', '');
              this.$set(this.directoryList[i].ChildDirectoryList[j], 'Filter2', '');
              this.$set(this.directoryList[i].ChildDirectoryList[j], 'PageIndex', 1);
              this.$set(this.directoryList[i].ChildDirectoryList[j], 'CourseInfoList', []);
              //获取第一页课程列表
              this.getCourseInfoList(this.directoryList[i].ChildDirectoryList[j]);
            }
          }
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //获取目录下课程列表
    getCourseInfoList(directory) {
      var model = {
        DirectoryGuid1: directory.ParentDirectoryGuid,
        DirectoryGuid2: directory.DirectoryGuid,
        DirectoryGuid3: directory.Filter1,
        CourseTag: directory.Filter2,
        pageIndex: directory.PageIndex,
        pageSize: 8,
      };
      this.$axios
        .post(Config.course.courseList, model)
        .then((res) => {
          let data = res.data.Data;
          directory.CourseInfoList.push(...data.List);
          if (directory.PageIndex >= data.PageCount) {
            directory.HasMore = false;
          } else {
            directory.HasMore = true;
          }
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //点击课程
    onItemClick(item) {
      this.$router.push({
        name: "Course1",
        params: {
          proxyCode: this.$store.state.proxyCode,
          courseGuid: item.CourseGuid
        }
      });
    },
    //点击过滤
    onFilterClick(directory) {
      directory.PageIndex = 1;
      directory.CourseInfoList = [];
      this.getCourseInfoList(directory);
    },
    //点击更多
    onMoreClick(directory) {
      directory.PageIndex++;
      this.getCourseInfoList(directory);
    },
  },
  mounted() {
    this.getRoutParams();
    this.getCourseDirectoryList();
  }
};
</script>
<style lang="less" scoped>
.content-carousel {
  margin-bottom: 10px;
  border-radius: 10px;
}

.content-pane {
  min-height: 100px;
  padding: 20px;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}

.course-info-line {
  border-bottom: 1px solid #e6e6e6;
  margin: 10px;
}

::v-deep .el-tabs__header {
  margin: 0px;
}
</style>