<template>
  <div>
    <van-sticky>
      <title-bar2 title="视频网课" />
    </van-sticky>
    <van-swipe class="content-info-swipe" :autoplay="3000" indicator-color="#FE5E03">
      <van-swipe-item>
        <van-image class="swipe-img" :src="proxySwipeImage(13)" fit="contain"></van-image>
      </van-swipe-item>
      <van-swipe-item>
        <van-image class="swipe-img" :src="proxySwipeImage(14)" fit="contain"></van-image>
      </van-swipe-item>
      <van-swipe-item>
        <van-image class="swipe-img" :src="proxySwipeImage(15)" fit="contain"></van-image>
      </van-swipe-item>
      <van-swipe-item>
        <van-image class="swipe-img" :src="proxySwipeImage(16)" fit="contain"></van-image>
      </van-swipe-item>
    </van-swipe>
    <course-info-filter class="course-info-filter" :directoryGuid1.sync="directoryGuid1" :directoryGuid2.sync="directoryGuid2" :directoryGuid3.sync="directoryGuid3" :courseTag.sync="courseTag" :directoryList="directoryList" @filter="onFilter"></course-info-filter>
    <course-info-list class="course-info-list" :loading.sync="loading" :finished.sync="finished" :list="courseInfoList" @data-load="onLoad" @item-click="onItemClick"></course-info-list>
  </div>
</template>

<script>
import TitleBar2 from "@/components/MT/TitleBar2";
import CourseInfoFilter from "@/components/MT/CourseInfoFilter";
import CourseInfoList from "@/components/MT/CourseInfoList";
import Tools from "@/tool";
import Config from "@/api/service";
import Manager from "@/manager";
export default {
  name: "MTModule",
  components: {
    TitleBar2,
    CourseInfoFilter,
    CourseInfoList,
  },
  props: {
  },
  data() {
    return {
      index: 0,
      total: 0,
      loading: false,
      finished: false,
      directoryGuid1: null,
      directoryGuid2: null,
      directoryGuid3: null,
      courseTag: null,
      directoryList: [],
      courseInfoList: [],
    };
  },
  computed: {
    proxySwipeImage() {
      return function (code) {
        return Config.proxy.image + `?ProxyCode=${this.$store.state.proxyCode}&ImageIdentity=${code}`;
      }
    }
  },
  methods: {
    //获取路由参数
    getRoutParams() {

    },
    //获取目录列表
    getCourseDirectoryList() {
      var model = {};
      this.$axios
        .post(Config.course.directoryList, model)
        .then((res) => {
          this.directoryList = res.data.Data.DirectoryList;
          //默认选中第一项
          if (this.directoryList.length > 0) {
            this.directoryGuid1 = this.directoryList[0].DirectoryGuid;
          }
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //获取课程列表
    getCourseInfoList() {
      var model = {
        DirectoryGuid1: this.directoryGuid1,
        DirectoryGuid2: this.directoryGuid2,
        DirectoryGuid3: this.directoryGuid3,
        CourseTag: this.courseTag,
        pageIndex: ++this.index,
        pageSize: 10,
      };
      this.$axios
        .post(Config.course.courseList, model)
        .then((res) => {
          let data = res.data.Data;
          this.loading = false;
          this.courseInfoList.push(...data.List);
          if (this.index >= data.PageCount) {
            this.finished = true;
          }
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //文件夹过滤改变
    onFilter() {
      this.onReload();
    },
    //重新加载列表数据
    onReload() {
      this.finished = false;
      this.loading = true;
      this.index = 0;
      this.courseInfoList = [];
      this.onLoad();
    },
    //加载当前页数据
    onLoad() {
      this.getCourseInfoList();
    },
    //点击课程
    onItemClick(item) {
      this.$router.push({
        name: "Course1",
        params: {
          proxyCode: this.$store.state.proxyCode,
          courseGuid: item.CourseGuid
        }
      });
    },
  },
  mounted() {
    this.getRoutParams();
    this.getCourseDirectoryList();
  }
};
</script>
<style lang="less" scoped>
.content-info-swipe {
  border-radius: 10px;
  max-height: 150px;
  margin: 0 10px;

  .swipe-img {
    display: unset;
    width: 100%;
    height: 100%;
  }
}

.course-info-filter {
  margin: 20px 10px 0 10px;
}

.course-info-list {
  margin: 20px 10px 0 10px;
}
</style>