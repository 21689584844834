<template>
  <div class="course-box">
    <div class="course-list">
      <div class="course-lesson" v-for="item in list" :key="item.CourseGuid">
        <course-info-item :courseGuid="item.CourseGuid" :courseName="item.CourseName" :courseAvailableQuantity="item.CourseAvailableQuantity" :courseSoldQuantity="item.CourseSoldQuantity" :courseOriginalPrice="item.CourseOriginalPrice" :courseSellingPrice="item.CourseSellingPrice" :courseTeacherList="item.CourseTeacherList" @click="onItemClick(item)"></course-info-item>
      </div>
    </div>
    <div v-if="hasMore" class="course-more" @click="onMoreClick">更多>></div>
  </div>
</template>

<script>
import CourseInfoItem from "@/components/PC/CourseInfoItem";

export default {
  name: "CourseInfoList",
  components: {
    CourseInfoItem
  },
  props: {
    list: {
      type: Array,
      default: [],
    },
    hasMore: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    onItemClick(item) {
      this.$emit("item-click", item);
    },
    onMoreClick() {
      this.$emit("more-click");
    },
  },
};
</script>

<style scoped lang="less">
.course-box {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.course-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.course-lesson {
  --width: 250px;
  --num: 4;
  --gap: calc((100% - var(--width) * var(--num)) / var(--num) / 2);
  margin: 10px var(--gap);
  width: var(--width);
}

.course-more {
  color: #FF6602;
  font-size: 14px;
  margin-left: auto;
  margin-right: 20px;
  cursor: pointer;
}
</style>
